


import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Ensure the path is correct
import 'bulma/css/bulma.css';
import './assets/styles.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
// Create the Vue application instance
const app = createApp(App);

// Use the router instance
app.use(router);

// Mount the Vue application to the DOM
app.mount('#app');
