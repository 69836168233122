<template>
    <nav class="navbar">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item">
          <!-- <img src="@/assets/images/kombij-logo-6kb.png" alt="NajamKombija Logo" class="navbar-logo"> -->
          <img src="@/assets/images/simple-logo.png" alt="NajamKombija Logo" class="navbar-logo">
          <b class="anton-sc-regular">Kombi.To.Go</b>
        </router-link>
        <a role="button" class="navbar-burger" aria-label="meni" aria-expanded="false" @click="toggleNavbar">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div :class="{ 'navbar-menu': true, 'is-active': isActive }">
        <div class="navbar-end">
          <router-link to="/prices" class="navbar-item">Cijene</router-link>
          <router-link to="/about" class="navbar-item">Kako funkcionira?</router-link>
          <a class="navbar-item">
            <button class="button is-primary" @click=goToReservations>Rezerviraj sada</button>
          </a>
        </div>
      </div>
    </nav>
  </template>
  
  <script>
  import { useRouter } from 'vue-router';
  export default {


    name: 'AppNavbar',
    setup(){
        const router = useRouter();
        
        const goToReservations = () => {
            router.push({ path: '/reservations', query: { city: "split" } });
          };
        
        return {
            goToReservations
        };
    },

    data() {
      return {
        isActive: false,
      };
    },
    methods: {
      toggleNavbar() {
        this.isActive = !this.isActive;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add any additional scoped styles here */
  nav.navbar {
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1); /* Adjust the shadow as needed */
  }
  </style>
  