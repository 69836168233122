<template>
  <div class="section reservations">
    <!-- City Dropdown -->
    <div class="container">
      <!-- <div class="box"> -->
        <!-- City Dropdown -->
        <div class="field box">
          <label class="label" for="city-dropdown">Odaberite grad</label>
          <div class="control">
            <div class="select">
              <select id="city-dropdown" v-model="selectedCity" @change="onCityChange">
                <option value="split">Split</option>
                <option value="zagreb">Zagreb</option>
                <option value="rijeka">Rijeka</option>
              </select>
            </div>
          </div>
        </div>

        <!-- Date and Hour Pickers -->
        <div class="field is-grouped is-grouped-multiline box">
          <!-- From Date and Hour -->
          <div class="field is-grouped">
            <div class="control">
              <label class="label" for="from-date">From Date</label>
              <input id="from-date" class="input" type="date" v-model="fromDate" />
            </div>
            <div class="control">
              <label class="label" for="from-hour">From Hour</label>
              <div class="select">
                <select id="from-hour" v-model="fromHour">
                  <option v-for="hour in timeSlots" :key="hour" :value="hour">{{ hour }}</option>
                </select>
              </div>
            </div>
          </div>

          <!-- To Date and Hour -->
          <div class="field is-grouped">
            <div class="control">
              <label class="label" for="to-date">To Date</label>
              <input id="to-date" class="input" type="date" v-model="toDate" />
            </div>
            <div class="control">
              <label class="label" for="to-hour">To Hour</label>
              <div class="select">
                <select id="to-hour" v-model="toHour">
                  <option v-for="hour in timeSlots" :key="hour" :value="hour">{{ hour }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

      <!-- Vans List -->
      <div class="content">
        <h2 class="title is-4">Dostupni kombiji - {{ formatCity(selectedCity) }}</h2>
        <div v-if="vansInTheCity.length === 0" class="notification is-info">
          No vans available in this city.
        </div>
        <div v-else>
          <div v-for="van in vansInTheCity" :key="van.id" class="box van-card">
            <h3 class="title is-5">{{van.title}}({{van.id}})</h3>
            <div class="columns is-mobile">
              <div class="column van-image-container">
                <img :src="resolveImagePath(van.image)" :alt="van.title" class="van-image" />
              </div>
              <div class="column info-table-container">
                <table class="table is-fullwidth">
                  <tbody>
                    <tr>
                      <td class="has-text-weight-bold">Cijena po satu</td>
                      <td>{{ formattedPrice(van.price) }}</td>
                    </tr>
                    <tr>
                      <td class="has-text-weight-bold">Minimalno vrijeme rezervacije</td>
                      <td>{{ van.min_duration }} h</td>
                    </tr>
                    <tr>
                      <td class="has-text-weight-bold">Max. volumen</td>
                      <td>{{ van.max_volume }}</td>
                    </tr>
                    <tr>
                      <td class="has-text-weight-bold">Max. težina</td>
                      <td>{{ van.max_load }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
            <div class="availability">
              <h4 class="title is-6">Dostupnost</h4>
              <div class="calendar-container">
                <button class="arrow-left" @click="scrollLeft(van.id)">&#9664;</button>
                
                <div class="calendar-grid" :ref="`calendarGridVanIdRef-${van.id}`">
                  <div v-for="day in filteredDays" :key="day" class="calendar-row">
                    <div class="calendar-date">{{ day }}</div>
                    <div v-for="timeSlot in filteredTimeSlots(day)" :key="timeSlot" class="calendar-cell">
                      <div
                      class="calendar-slot"
                      :class="isOccupied(van.id, day, timeSlot) ? 'reserved' : 'available'"
                      @click="openReservationModal(van, timeSlot, day)"
                      >
                      {{ timeSlot }} - {{ isOccupied(van.id, day, timeSlot) ? 'Nije dostupno' : 'Dostupno' }}
                    </div>
                  </div>
                </div>
              </div>
              
              <button class="arrow-right" @click="scrollRight(van.id)">&#9654;</button>
            </div>
          </div>
          <button class="button is-primary" @click="openReservationModal(van, null, day)" >Rezerviraj</button>
          </div>
        </div>
      </div>
    

      


    </div>

    <!-- Reservation Modal -->
    <div v-if="isModalOpen" class="modal is-active">
      <div class="modal-background" @click="closeReservationModal"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Reserve {{ selectedVan.title }} for {{ selectedVan.timeSlot }} on {{ selectedVan.date }}</p>
          <button class="delete" aria-label="close" @click="closeReservationModal"></button>
        </header>
        <section class="modal-card-body">
          <calendar-component :van="selectedVan" @reserve="handleReservation" />
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" @click="handleReservation">Rezerviraj</button>
          <button class="button" @click="closeReservationModal">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>



<script>
import { ref, watch, onMounted, computed, getCurrentInstance } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import CalendarComponent from '@/components/CalendarComponent.vue';
import supabase from '@/services/supabaseClient';  // Adjust the path if needed
import moment from 'moment';

export default {
  name: 'ReservationsView',
  components: { CalendarComponent },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const selectedCity = ref(route.query.city || 'split');
    const isModalOpen = ref(false);
    const selectedVan = ref(null);
    const vansInTheCity = ref([]);
    const reservations = ref({});
    
    // Logic related to the From/To dropdowns
    const timeSlots = ['09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00'];

    const today = new Date();

    const days = Array.from({ length: 10 }, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() + i);
      return date.toISOString().split('T')[0];
    });

    const formatDate = (date) => date.toISOString().split('T')[0];

    const fromDate = ref(formatDate(today));
    const toDate = ref(formatDate(today));

    const twoHoursFromNow = new Date();
    twoHoursFromNow.setHours(twoHoursFromNow.getHours() + 2, 0, 0, 0); // Add 2 hours and round to the beginning of the hour
    const fourHoursFromNow = new Date();
    fourHoursFromNow.setHours(fourHoursFromNow.getHours() + 3, 0, 0, 0); // Add 2 hours and round to the beginning of the hour

    const fromHour = ref(`${twoHoursFromNow.getHours()}:00`);
    const toHour = ref(`${fourHoursFromNow.getHours()}:30`);

    const filteredDays = computed(() => {
      if (!fromDate.value || !toDate.value) return days;
      return days.filter(day => day >= fromDate.value && day <= toDate.value);
    });

    // It removes the hours before the From hour for the day one and hours after the "to" for the last filtered day
    const filteredTimeSlots = (day)=>{
      
      // Convert the current day we're looping through slots for to datetime
      const dayDateTime = moment(day)

      // Convert the filtered days and hours to datetimes so that we can filter out hours for the first and the last day
      const fromDateHourDateTime = moment(`${fromDate.value}T${fromHour.value}:00`)
      const toDateHourDateTime = moment(`${toDate.value}T${toHour.value}:00`)
      
      // If we're retunring slots for the first day only return the time slots after the filtered hour
      if( dayDateTime.isSame(fromDateHourDateTime, 'day') && dayDateTime.isSame(toDateHourDateTime, 'day') ){
        // Return only time slots for the day that are after the hour given by fromDateHourDateTime
        
        return timeSlots.filter(slot => {
          // Split the time slot "10:30" string into hour and minutes
          const [hour, minutes] = slot.split(':').map(Number);
          const fromHour = fromDateHourDateTime.hours()
          const fromMinutes = fromDateHourDateTime.minutes()

          const toHour = toDateHourDateTime.hours()
          const toMinutes = toDateHourDateTime.minutes()

          
          // Compare with fromMinute and fromMinutes
          if (hour >= fromHour && hour <= toHour) {
            return true; // Slot is later in the day
          } else if (hour === fromHour || hour === toHour) {            
            return true
          
          } else {
            return false; // Slot is earlier in the day
          }
        });     

      } 
      
      // If it's a day in the middle return all
      if (!dayDateTime.isSame(fromDateHourDateTime, 'day') && !dayDateTime.isSame(toDateHourDateTime, 'day')){
        return timeSlots;
      }
      if(dayDateTime.isSame(fromDateHourDateTime, 'day')){
        // If we're returning slots for the last day apply filters
        return timeSlots.filter(slot => {
          // Split the time slot string into hour and minutes
          const [hour, minutes] = slot.split(':').map(Number);
          const fromHour = fromDateHourDateTime.hours()
          const fromMinutes = fromDateHourDateTime.minutes()

          // Compare with fromMinute and fromMinutes
          if (hour >= fromHour) {
            return true; // Slot is later in the day
          } else {
            return false; // Slot is earlier in the day
          }
        });
        // return timeSlots
      }

      if(dayDateTime.isSame(toDateHourDateTime, 'day')){
        // If we're returning slots for the last day apply filters
        return timeSlots.filter(slot => {
          // Split the time slot string into hour and minutes
          const [hour, minutes] = slot.split(':').map(Number);
          const toHour = toDateHourDateTime.hours()
          const toMinutes = toDateHourDateTime.minutes()

          // Compare with fromMinute and fromMinutes
          if (hour <= toHour) {
            return true; // Slot is later in the day
          } else {
            return false; // Slot is earlier in the day
          }
        });      
      }
    };

    const formattedPrice = (price)=>{
      // Format the price as "20,00€"
      const formatted = (price / 100).toFixed(2).replace('.', ',');
      return `${formatted}€`;
    }

    // On every city or time change it fetches vans from the city and then reservations for those vans.
    // It returns vans reservations
    const fetchVans = async () => {
      try {
        const cityId = await fetchCityId(selectedCity.value);

        if (!cityId) {
          throw new Error('City ID could not be found');
        }

        const { data, error } = await supabase
          .from('vans')
          .select('*')
          .eq('city_id', cityId);

        if (error) throw error;

        vansInTheCity.value = data;
        
        // Fetch reservations for all available vans
        vansInTheCity.value.forEach(van => fetchReservations(van.id));
        
      } catch (error) {
        console.error('Fetch vans failed:', error);
        vansInTheCity.value = [];
      }
    };

    const fetchCityId = async (cityName) => {
      try {
        const { data, error } = await supabase
          .from('cities')
          .select('id')
          .eq('name', cityName)
          .single(); // We expect a single result

        if (error) throw error;
        
        return data.id;
      } catch (error) {
        console.error('Fetch city ID failed:', error);
        return null; // Return null or handle the error as needed
      }
    };

    const fetchReservations = async (vanId) => {
      try {
        const { data, error } = await supabase
          .from('reservations')
          .select('*')
          .eq('van_id', vanId)
          .gte('reservation_start', `${fromDate.value}T09:00:00`)
          .lte('reservation_end', `${toDate.value}T22:00:00`);

        if (error) throw error;

        reservations.value[vanId] = data;

        console.log(`fetchReservations for vanId ${vanId} between ${fromDate.value}/${fromHour.value} and ${toDate.value}/${toHour.value} returned ${data.length} reservations`)
        if(data.length > 0){
          console.log(`Here's the data:`)
          console.dir(data)
        }

      } catch (error) {
        console.error('Fetch reservations failed:', error);
        reservations.value[vanId] = [];
      }
    };

    const onCityChange = () => {
      fetchVans();
      router.replace({ query: { city: selectedCity.value } });
    };

    const resolveImagePath = (imageName) => {
      try {
        return require(`@/assets/images/${imageName}`);
      } catch (error) {
        return require('@/assets/images/vito.png');
      }
    };

    const openReservationModal = (van, timeSlot, date) => {
      selectedVan.value = { ...van, timeSlot, date };
      isModalOpen.value = true;
    };

    const closeReservationModal = () => {
      isModalOpen.value = false;
    };

    const handleReservation = (reservation) => {
      console.log('Reservation Details:', reservation);
      closeReservationModal();
    };

    // It takes vanId, current calendar grid day and slot (9:00-9:30) and drop down from to information
    const isOccupied = (vanId, timeSlotDay, timeSlotStart, startDate, startHour, endDate, endHour) => {
      const availability = reservations.value[vanId] || [];
      
      // Current calendar slot datetime that is being shown in the calendar grid
      const currentSlotStartDateTime = new Date(`${timeSlotDay}T${timeSlotStart}:00`);
      const currentSlotEndDateTime = new Date(currentSlotStartDateTime); // Create a new Date object
      currentSlotEndDateTime.setMinutes(currentSlotEndDateTime.getMinutes() + 30); //Add 30 min
          
      
      return availability.some(reservation => {
        const resStart = new Date(reservation.reservation_start);
        const resEnd = new Date(reservation.reservation_end);
        // Check if the current slot overlaps with the filtered slot
        return (
          currentSlotStartDateTime >= resStart &&
          currentSlotEndDateTime <= resEnd
        );
        // return (resStart < filteredEndDateTime && resEnd > filteredStartDateTime); // Check overlap
      });
    };

    const formatCity = (city) => {
      return city ? city.charAt(0).toUpperCase() + city.slice(1) : 'Unknown City';
    };

    watch([fromDate, toDate, fromHour, toHour], () => {
      fetchVans();
    });

    // Get the current instance to access the refs in the template
    const instance = getCurrentInstance();


    const scrollLeft = (vanId) => {
      console.log("Scrolling left for the van "+vanId)
      const calendarGrid = instance.refs[`calendarGridVanIdRef-${vanId}`][0];
      // debugger
      
      if (calendarGrid) {
        calendarGrid.scrollLeft -= 180;  // Adjust the scroll value as needed
      }
    };

    const scrollRight = (vanId) => {
      
      console.log("Scrolling right for the van "+vanId)
      const calendarGrid = instance.refs[`calendarGridVanIdRef-${vanId}`][0];
      
      if (calendarGrid) {
        calendarGrid.scrollLeft += 180;  // Adjust the scroll value as needed
      }
    };
  

    onMounted(async () => {
      await fetchVans();
    });

    return {
      selectedCity,
      isModalOpen,
      selectedVan,
      vansInTheCity,
      timeSlots,
      days,
      filteredDays,
      fromDate,
      toDate,
      fromHour,
      toHour,
      openReservationModal,
      closeReservationModal,
      handleReservation,
      isOccupied,
      onCityChange,
      formatCity,
      resolveImagePath,
      formattedPrice,
      scrollLeft, 
      scrollRight,
      filteredTimeSlots
      
    };
  }
};
</script>




<style scoped>
/* General section styling */
.van-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.van-image {
  min-width: 10rem;
  max-width: 20%;
  height: auto;
  margin-bottom: 15px;
}

.availability {
  margin-top: 20px;
}

.calendar-container {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}





.calendar-grid {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 10px 0;
  
}

.calendar-row {
  display: flex;
  align-items: stretch; /* Ensure children take the full height of the row */
  font-size: 0.75rem;
}

.calendar-date,
.calendar-cell {
  display: flex;
  align-items: center;
  justify-content: center; /* Center content horizontally and vertically */
  /* padding: 10px; */
  margin: 0 5px; /* Optional: Adjust margin as needed */
  min-width: 100px; /* Minimum width */
  background-color: #f5f5f5;
  border-radius: 4px;
  box-sizing: border-box; /* Ensure padding is included in width */
}

.calendar-date {
  font-weight: bold;
  background-color: #e0e0e0; /* Slightly different color to distinguish from cells */
}

.calendar-cell {
  background-color: #fff; /* White background for cells */
}

/* Optional: Ensure scrollbar is styled nicely */
.calendar-grid::-webkit-scrollbar {
  height: 8px;
}

.calendar-grid::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.calendar-grid::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}




.calendar-slot {
  padding: .5rem;
  border-radius: 4px;
  text-align: center;
  /* font-size: .75rem; */
  cursor: pointer;
  min-width: 100px;
}

.calendar-slot.available {
  background-color: #e0f7fa;
  color: #00796b;
}

.calendar-slot.reserved {
  background-color: #ffcdd2;
  color: #d32f2f;
  text-decoration: line-through;
}

.arrow-left, .arrow-right {
  background-color: #00796b;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.arrow-left {
  margin-right: 10px;
}

.arrow-right {
  margin-left: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .calendar-row {
    min-width: 100px;
  }
}



/* General section styling */
.van-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.van-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.van-image {
  width: 100%;
  max-width: 360px; /* Max width for mobile */
  height: auto;
}

.info-table-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table {
  margin: 0;
}

.info-table-container .table td {
  padding: 5px;
  text-align: left;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .van-card .columns {
    flex-direction: row;
  }
  
  .van-image-container {
    width: 30%; /* 30% width on tablets/desktops */
    text-align: left;
  }

  .info-table-container {
    width: 70%; /* 70% width on tablets/desktops */
    text-align: left;
  }

  .info-table-container .table {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .van-card .columns {
    flex-direction: column;
  }

  .van-image-container {
    margin-bottom: 15px;
    width: 100%;
  }

  .info-table-container {
    width: 100%;
    text-align: left;
  }

  .info-table-container .table {
    width: 100%;
  }
}



</style>