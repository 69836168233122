<template>
    <section class="section">
        <div class="container content">
            <h1 class="title">Uvjeti poslovanja</h1>

            <p><strong>Dobrodošli u Kombi na Sat d.o.o.</strong></p>
            <p>Ovi uvjeti poslovanja definiraju pravila i uvjete pod kojima se kombiji iznajmljuju korisnicima. Registracijom i rezervacijom vozila putem naše web stranice, korisnik izričito pristaje na ove uvjete poslovanja. Svaka rezervacija smatra se valjanim potpisanim ugovorom između korisnika i Kombi na Sat d.o.o.</p>

            <h2 class="subtitle">Sadržaj</h2>
            <ul>
                <li><a href="#uvod">Uvod</a></li>
                <li><a href="#definicije">Definicije</a></li>
                <li><a href="#osnova-ugovora">Osnova ugovora</a></li>
                <li><a href="#rezervacija-i-plačanje">Rezervacija i plačanje</a></li>
                <li><a href="#zahtjevi-za-vozače">Zahtjevi za vozače</a></li>
                <li><a href="#povrat-vozila">Povrat vozila</a></li>
                <li><a href="#čistoća-vozila">Čistoća vozila</a></li>
                <li><a href="#šteta-na-vozilu">Šteta na vozilu</a></li>
                <li><a href="#korištenje-vozila-i-dodatni-uvjeti">Korištenje vozila i dodatni uvjeti</a></li>
                <li><a href="#produženje-rezervacije">Produženje rezervacije</a></li>
                <li><a href="#otkazivanje-rezervacije">Otkazivanje rezervacije</a></li>
                <li><a href="#nadležni-sud">Nadležni sud</a></li>
            </ul>


            
            <h2 id="definicije" class="title is-4">Definicije</h2>
            <p>
                <ol>
                    <li>Vi/vaše: Fizicka ili pravna osoba koja unajmljuje vozilo od nas tvrtke Kombij na sat d.o.o. registrirane u RH</li> 
                    <li>Mi/naše: Tvrtka Kombij na sat d.o.o od koje korisnik iznajmljuje vozilo</li> 
                    <li>Rezervacija: Vaš zahtjev za najam vozila od nas u određenom vremenskom trajanju</li>
                    <li>Poslovni korisnik: Poslovna osoba, tvrtka, partnerstvo ili tvrtka koja s nama ima sklopljen ugovor o poslovnom najmu.</li>
                    <li>Poslovni sati: Radno vrijeme lokacije na kojoj se vozilo vraća.</li>
                    <li>Ugovor: Značenje definirano prema našim uvjetima korištenja.</li>
                    <li>Usluga dostave i preuzimanja: Dostava vozila na dogovorenu lokaciju i preuzimanje vozila nakon isteka razdoblja najma.</li>
                    <li>Prozor za dostavu: Vremenski okvir od dva sata prije ugovorenog vremena dostave i preuzimanja.</li>
                    <li>Digitalni najam: Samostalna rezervacija i najam vozila putem naše mobilne aplikacije.</li>
                    <li>Potrebni dokumenti: Vozačka dozvola, osobna iskaznica i kreditna kartica. Dokumenti ne smiju biti stariji od 3 mjeseca.</li>
                    <li>Najam vozila: Sklapanje ugovora o najmu vozila s nama, uključujući vozilo, ključeve i sve dodatke.</li>
                    
                </ol>
            </p>

            <h4 class="title is-4">Dodatni uvjeti</h4>
            <p>
                <ul>
                    <!-- <li>Dostava i preuzimanje: Naša usluga uključuje dostavu vozila na dogovorenu lokaciju i preuzimanje vozila na kraju razdoblja najma</li> -->
                    <li>Manja oštećenja: Ako su na vozilu prisutna manja oštećenja, troškovi popravka naplatit će se iz sigurnosnog pologa</li>
                    <li>Odgovornost korisnika: Korisnik je odgovoran za sve štete, sudare, prekršaje i druge incidente tijekom trajanja najma</li>
                    <li>Telematička oprema: Naša vozila su opremljena GPS uređajima i telematikama koje prate lokaciju i upotrebu vozila kako bi se osigurala sukladnost s uvjetima korištenja</li>
                    <li>Dokumenti potrebni za najam: Vozačka dozvola i važeća kreditna kartica (za blokiranje depozita)</li>
                </ul>
            </p>

            <h2 id="uvod" class="title is-4">Uvod</h2>
            <p>Dobrodošli u Kombi na Sat d.o.o. Ovi uvjeti poslovanja definiraju pravila i uvjete pod kojima se kombiji iznajmljuju korisnicima. Registracijom i rezervacijom vozila putem naše web stranice, korisnik izričito pristaje na ove uvjete poslovanja. Svaka rezervacija smatra se valjanim potpisanim ugovorom između korisnika i Kombi na Sat d.o.o.</p>

            <h2 id="osnova-ugovora" class="title is-4">Osnova ugovora</h2>
            
            <p>
                <ol>
                    <li><strong>Ugovor:</strong> Ugovor se sklapa između vas (korisnika) i nas (Kombij na sat d.o.o) te definira naše odgovornosti prema vama i vaše odgovornosti prema nama prilikom najma vozila i bilo kojih dodatnih opcija. Ugovor se sastoji od sljedećih dokumenata:
                        <ul>
                            <li>(a) Ugovor o najmu;</li>
                            <li>(b) Ovi uvjeti; i</li>
                            <li>(c) Izvješće o stanju vozila (ako ste ga zatražili).</li>
                        </ul>
                    </li>
                    <li><strong>Rezervacija:</strong> Kada dovršite rezervaciju putem telefona ili online obrasca i kliknete na gumb "Rezerviraj odmah", obvezujemo se koristiti razumne napore kako bismo vam osigurali vozilo iz odabrane kategorije. Ako to nije moguće, osigurat ćemo vam alternativno vozilo slične specifikacije iz više kategorije. Vi prihvaćate sljedeće:
                        <ul>
                            <li>(a) Ugovor je skklopljen u trenutku rezervacije vozila putem web stranice ili telefonskim putem</li>
                            <li>(b) Bit ćete obvezani ugovorom o najmu i ovim uvjetima kad se ugovor sklopi</li>
                        </ul>
                    </li>
                    
                    <li><strong>Sklapanje ugovora:</strong> Ugovor se smatra sklopljenim u trenutku kreiranja rezervacije. Prije toga morate dati na uvid potrebne dokumente, odobriti sigurnosni depozit na kreditnoj kartici, potvrditi stanje vozila i zadovoljiti uvjete najma.</li>
                    
                    <li><strong>Obveze korisnika:</strong> Sklapanjem ugovora obvezujete se:
                        <ul>
                            <li>(a) Iznajmiti vozilo i eventualne dodatne opcije za razdoblje najma;</li>
                            <li>(b) Platiti naknade za najam i druge dodatne troškove (produljenje razdoblja najma, opcionalne dodatke itd.);</li>
                            <li>(c) Platiti sve administrativne troškove, naknade, kazne, troškove suda i druge relevantne naknade u skladu s ovim uvjetima.</li>
                            <li>(d) Platiti sve troškove štete ili nemara koji nastanu usred u skladu s ovim uvjetima.</li>
                        </ul>
                    </li>
                    <li><strong>Poslovni korisnici:</strong> Ako ste poslovni korisnik, potpisivanjem ugovora potvrđujete da imate ovlaštenje tvrtke za sklapanje ugovora. Ako postoji sukob između uvjeta ugovora i uvjeta poslovnog ugovora, primjenjivat će se uvjeti poslovnog ugovora.</li>
                    <li><strong>Izmjene uvjeta:</strong> Zadržavamo pravo izmjene ovih uvjeta, uključujući administrativne naknade, uz prethodnu pisanu obavijest najmanje 7 dana unaprijed.</li>
                </ol>
            </p>



            <h2 id="rezervacija-i-plačanje" class="title is-4">Rezervacija i plačanje</h2>
            <p>Kombi se može rezervirati na određeni datum između dvaju unaprijed definiranih vremenskih intervala, pod uvjetom da je vozilo dostupno u tom vremenskom okviru. Za svaki rezervirani period  na kartici korisnika blokiraju se sredstva prema tarifi. Točan iznos blokade bit će prikazan prilikom rezervacije.</p>

            <h2 id="zahtjevi-za-vozače" class="title is-4">Zahtjevi za vozače</h2>
            <p>Vozači moraju imati najmanje 21 godinu i moraju prikazati važeću kreditnu karticu i vozačku dozvolu s najmanje dvije godine iskustva.</p>

            <h2 id="povrat-vozila" class="title is-4">Povrat vozila</h2>
            <p>Kombi mora biti vraćen na vrijeme prema dogovorenom vremenu i na adresu označenu na web stranici. Vozilo mora biti vraćeno najkasnije na kraj rezerviranog termina kako bi se omogućilo da sljedeći korisnik započne svoju rezervaciju. Kašnjenje u povratu bit će kažnjeno prema sljedećim uvjetima:</p>
            <ul>
                <li>Kašnjenje do 30 minuta: dodatna naknada od 20% od ukupne cijene najma.</li>
                <li>Kašnjenje od 30 do 60 minuta: dodatna naknada od 50% od ukupne cijene najma.</li>
                <li>Kašnjenje duže od 60 minuta: dodatna naknada od 100% od ukupne cijene najma.</li>
            </ul>

            <h2 id="čistoća-vozila" class="title is-4">Čistoća vozila</h2>
            <p>Kombi se mora vratiti u istom stanju u kojem je preuzet. Ako vozilo bude vraćeno prljavo, naplatit će se dodatna naknada:</p>
            <ul>
                <li>Lagano čišćenje: 100 HRK</li>
                <li>Temeljito čišćenje: 250 HRK</li>
            </ul>

            <h2 id="šteta-na-vozilu" class="title is-4">Šteta na vozilu</h2>
            <p>Ako se na vozilu uoče bilo kakva oštećenja, bilo s vanjske ili unutarnje strane, ili ako se vozilo vrati nečisto i zahtijeva pranje, trošak popravka ili čišćenja bit će naplaćen s kreditne kartice korisnika. Ako se iznos ne može naplatiti s kreditne kartice, za bilo kakve sporove bit će nadležan Trgovački sud u Zagrebu.</p>
            <p>Za vrijeme trajanja rezervacije, korisnik je odgovoran za sve štete, sudare, prekršaje i druge incidente koji se mogu dogoditi tokom korištenja vozila.</p>

            <h2 id="korištenje-vozila-i-dodatni-uvjeti" class="title is-4">Korištenje vozila i dodatni uvjeti</h2>
            <p>Vozila su opremljena GPS uređajima i kamerama koji nadziru upotrebu vozila i osiguravaju da je vozilo korišteno sukladno uvjetima korištenja. Zabranjeno je pušenje u vozilu. Ako se uoče tragovi pušenja, korisnik će biti kažnjen dodatnom naknadom od 500 HRK. Ako se uoče bilo kakvi znakovi neadekvatne upotrebe, uključujući neovlaštene vozače, zagađenje unutrašnjosti ili oštećenja uzrokovana nepravilnim rukovanjem, naplatit će se dodatne naknade prema procjeni troškova.</p>

            <h2 id="produženje-rezervacije" class="title is-4">Produženje rezervacije</h2>
            <p>Ako korisnik shvati da treba produžiti rezervaciju, produženje je moguće bez dodatne naknade ako se obavijesti tvrtka Kombi na Sad barem sat vremena prije isteka rezervacije. Rezervacija se ne može produžiti ako je kombi rezerviran odmah nakon trenutne rezervacije.</p>

            <h2 id="otkazivanje-rezervacije" class="title is-4">Otkazivanje rezervacije</h2>
            <p>Rezervaciju možete otkazati najkasnije 24 sata prije početka najma bez naknade. Otkazivanja u manje od 24 sata podliježu naknadi od 50% ukupne cijene najma.</p>

            <h2 id="nadležni-sud" class="title is-4">Nadležni sud</h2>
            <p>U slučaju bilo kakvih sporova ili tužbi vezanih uz uvjete najma, nadležan je Trgovački sud u Zagrebu.</p>

            <p>Ovi uvjeti mogu se mijenjati prema potrebama i poslovnoj praksi. Uvijek provjerite najnovije uvjete prilikom rezervacije.</p>
            
        </div>
    </section>    
    
        
    
</template>
<script></script>
<style></style>
