import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import TermsView from '../views/TermsView.vue';
import AboutView from '../views/AboutView.vue';
import PricesView from '../views/PricesView.vue';
import ReservationsView from '../views/ReservationsView.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/terms',
    name: 'Terms',
    component: TermsView
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView
  },
  {
    path: '/prices',
    name: 'Prices',
    component: PricesView
  },
  {
    path: '/reservations',
    name: 'ReservationView',
    component: ReservationsView
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes
});

export default router;
