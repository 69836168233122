<template>
    <section class="section benefits">
            <div class="container">
                <div class="columns is-vcentered">
                    <div class="column is-6">
                        <h2 class="title anton-sc-regular anton-sc-regular">Trebaš prevesti nešto?</h2>
                        <p>Najam kombija po satu omogućuje vam da platite samo za vrijeme koje vam je potrebno, što može biti znatno jeftinije od dnevnih ili tjednih cijena. Ovo je idealno za kratkoročne projekte, premještanje malih tereta ili obavljanje poslova bez nepotrebnih troškova.</p>
                    </div>
                    <div class="column is-6">
                        <img src="@/assets/images/person_with_laptop.svg" alt="Ilustracija koristi 3">
                    </div>
                </div>
                <div class="columns is-vcentered">
                    <div class="column is-6">
                        <img src="@/assets/images/woman_lying.svg" alt="Ilustracija koristi 2">
                    </div>
                    <div class="column is-6">
                        <h2 class="title anton-sc-regular">Rezervirajte po satu. Bez obveza</h2>
                        <p>Najam kombija po satu omogućuje vam da platite samo za vrijeme koje vam je potrebno, što može biti znatno jeftinije od dnevnih ili tjednih cijena. Ovo je idealno za kratkoročne projekte, premještanje malih tereta ili obavljanje poslova bez nepotrebnih troškova.</p>
                    </div>
                </div>
                <div class="columns is-vcentered">
                    <div class="column is-6">
                        <h2 class="title anton-sc-regular">Jednostavna rezervacija i preuzimanje</h2>
                        <p>Brzo rezervirajte kombi putem interneta ili mobilne aplikacije i preuzmite ga na prikladnoj lokaciji. Proces je pojednostavljen, štedeći vam vrijeme u usporedbi s tradicionalnim najmom ili vlasništvom vozila, i izbjegava potrebu za fizičkim papirom.</p>
                    </div>
                    <div class="column is-6">
                        <img src="@/assets/images/person_with_laptop.svg" alt="Ilustracija koristi 3">
                    </div>
                </div>
                <div class="columns is-vcentered">
                    <div class="column is-6">
                        <img src="@/assets/images/delivery.svg" alt="Ilustracija koristi 1">
                    </div>
                    <div class="column is-6">
                        <h2 class="title anton-sc-regular">Potpuna fleksibilnost i trenutni pristup</h2>
                        <p>Pristupite kombiju odmah kada vam zatreba bez ikakvih smetnji. Idealno za gradsku populaciju koja povremeno treba vozilo, a ne želi se brinuti o parkiranju ili održavanju vozila u prenatrpanim urbanim područjima.</p>
                    </div>
                </div>
            </div>
        </section>

  </template>
  
  <script>
  export default {
    name: 'HomeBenefitsSection'
  };
  </script>
  
  <style scoped>
  /* Add any specific styles for the About page here */
  </style>
  