<template>
  
  <HomeHeroSection />
  <HomeBenefitsSection />
  <HomeCarouselSection />

</template>

<script>

import HomeHeroSection from './sections/HomeHeroSection.vue'
import HomeBenefitsSection from './sections/HomeBenefitsSection.vue'

import HomeCarouselSection from './sections/HomeCarouselSection.vue'
export default {
  name: 'HomeView',
  components: {HomeHeroSection, HomeBenefitsSection, HomeCarouselSection}
}
</script>

<style scoped>
/* Your styles here */
</style>
