import { createClient } from '@supabase/supabase-js';

// Replace these with your Supabase project URL and Anon key
const SUPABASE_URL = 'https://okpczjjskcdforczmvez.supabase.co'
const SUPABASE_ANON_KEY = process.env.VUE_APP_SUPABASE_KEY //In Netlify it has to be set with the VUE_APP_ prefix - VUE_APP_SUPABASE_KEY

// const SUPABASE_ANON_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9rcGN6ampza2NkZm9yY3ptdmV6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjI1MjQyNzUsImV4cCI6MjAzODEwMDI3NX0.qnJbOA0_qGACLKdOsg16rr84fXSCJv_7VBOatGCy2Po'

const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);


export default supabase;
