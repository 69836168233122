<template>
    <div class="calendar-component">
      <h3>Select Time Slots for {{ van.title }}</h3>
      <div class="calendar-grid">
        <div v-for="hour in hours" :key="hour" 
             :class="{ 'selected': isSelected(hour) }"
             @click="toggleHour(hour)">
          {{ hour }}
        </div>
      </div>
      <button @click="reserve">Rezerviraj</button>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  
  export default {
    name: 'CalendarComponent',
    props: {
      van: Object
    },
    setup(props, { emit }) {
      const selectedHours = ref([]);
      const hours = ['12:00-13:00', '13:00-14:00', '14:00-15:00', '15:00-16:00', '16:00-17:00', '17:00-18:00'];
  
      const isSelected = (hour) => selectedHours.value.includes(hour);
  
      const toggleHour = (hour) => {
        if (isSelected(hour)) {
          selectedHours.value = selectedHours.value.filter(h => h !== hour);
        } else {
          selectedHours.value.push(hour);
        }
      };
  
      const reserve = () => {
        emit('reserve', {
          van: props.van,
          hours: selectedHours.value
        });
      };
  
      return {
        hours,
        isSelected,
        toggleHour,
        reserve
      };
    }
  };
  </script>
  
  <style scoped>
  .calendar-component {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 5px;
    margin-bottom: 20px;
  }
  
  .calendar-grid div {
    padding: 10px;
    text-align: center;
    cursor: pointer;
  }
  
  .calendar-grid .selected {
    background-color: blue;
    color: white;
  }
  
  button {
    background-color: #3273dc;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  </style>
  