<template>
    <section class="hero is-primary is-bold">
        <div class="hero-video">
            <video autoplay muted loop id="hero-video">
                <source src="@/assets/images/hero-video.webm" type="video/webm">
                Vaš preglednik ne podržava video tag.
            </video>
            <div class="overlay"></div>
        </div>
        <div class="hero-body">
            <div class="container">
                <div class="columns">
                    <div class="column is-half">
                        <div class="hero-content has-text-left">
                            <h1 class="title has-text-white with-stroke anton-sc-regular">Najam kombija po satu u Zagrebu, Splitu i Rijeci</h1>
                            <p class="subtitle has-text-white">Prikladni i povoljni najam kombija</p>
                            <button class="button is-primary is-light" @click="goToReservations">Rezerviraj sada</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

  </template>
  
  <script>
  
  import { useRouter } from 'vue-router';
  export default {
    
    name: 'HomeHeroSection',
    setup(){
        const router = useRouter();
        
        const goToReservations = () => {
            router.push({ path: '/reservations', query: { city: "split" } });
          };
        
        return {
            goToReservations
        };
    },



  };
  </script>
  
  <style scoped>
  /* Add any specific styles for the About page here */
  </style>
  