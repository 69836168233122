<template>
    <!-- Carousel Section -->
    <section class="section carousel">
      <div class="container">
        <div class="tabs">
          <ul>
            <li :class="{ 'is-active': activeTab === 'split' }">
              <a @click="changeTab('split')">Split</a>
            </li>
            <li :class="{ 'is-active': activeTab === 'zagreb' }">
              <a @click="changeTab('zagreb')">Zagreb</a>
            </li>
            <li :class="{ 'is-active': activeTab === 'rijeka' }">
              <a @click="changeTab('rijeka')">Rijeka</a>
            </li>
          </ul>
        </div>
        <div class="carousel-wrapper" @mouseenter="stopAutoScroll" @mouseleave="startAutoScroll">
          <button class="carousel-control left" aria-label="Prethodno" @click="scrollLeft">
            <span class="icon"><i class="fas fa-chevron-left"></i></span>
          </button>
          <div class="carousel-inner" ref="carouselInner">
            <div class="carousel-items">
              <div class="carousel-item" v-for="item in filteredItems" :key="item.id">
                <div class="item-content">
                  <h3 class="title">{{ item.title }}</h3>
                  <p class="subtitle">{{ item.subtitle }}</p>
                  <img :src="item.image" :alt="item.title">
                  <button class="button is-primary" @click="goToReservations">Rezerviraj sada</button>
                </div>
              </div>
            </div>
          </div>
          <button class="carousel-control right" aria-label="Sljedeće" @click="scrollRight">
            <span class="icon"><i class="fas fa-chevron-right"></i></span>
          </button>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { onMounted, onBeforeUnmount, ref, computed, nextTick } from 'vue';
  import { useRouter } from 'vue-router';
  
  export default {
    name: 'HomeCarouselSection',
    setup() {
      const router = useRouter();
      const items = {
        split: [
          { id: 1, title: 'Mercedes-Benz Vito', subtitle: '€20/sat', image: require('@/assets/images/vito.png') },
          { id: 2, title: 'Iveco Daily', subtitle: '€22/sat', image: require('@/assets/images/iveco.png') },
          { id: 3, title: 'Citroën Jumper', subtitle: '€18/sat', image: require('@/assets/images/jumper.png') },
          { id: 4, title: 'Ford Transit', subtitle: '€21/sat', image: require('@/assets/images/iveco.png') },
          { id: 5, title: 'Volkswagen Transporter', subtitle: '€25/sat', image: require('@/assets/images/vito.png') },
          // Add more items for Split
        ],
        zagreb: [
          { id: 6, title: 'Peugeot Boxer', subtitle: '€24/sat', image: require('@/assets/images/boxer.png') },
          { id: 7, title: 'Renault Master', subtitle: '€26/sat', image: require('@/assets/images/master.png') },
          { id: 8, title: 'Opel Movano', subtitle: '€23/sat', image: require('@/assets/images/movano.png') },
          { id: 9, title: 'Ford Transit Custom', subtitle: '€27/sat', image: require('@/assets/images/jumper.png') },
          // Add more items for Zagreb
        ],
        rijeka: [
          { id: 10, title: 'Mercedes-Benz Vito', subtitle: '€20/sat', image: require('@/assets/images/vito.png') },
          { id: 11, title: 'Iveco Daily', subtitle: '€22/sat', image: require('@/assets/images/iveco.png') },
          { id: 12, title: 'Citroën Jumper', subtitle: '€18/sat', image: require('@/assets/images/jumper.png') },
          { id: 13, title: 'Ford Transit', subtitle: '€21/sat', image: require('@/assets/images/iveco.png') },
          { id: 14, title: 'Volkswagen Transporter', subtitle: '€25/sat', image: require('@/assets/images/vito.png') },
          // Add more items for Rijeka
        ]
      };
  
      const activeTab = ref('split');
      const carouselInner = ref(null);
      let scrollInterval = null;
  
      const filteredItems = computed(() => items[activeTab.value]);
  
      const scrollLeft = async () => {
        await nextTick(); // Ensure DOM is updated
        const container = carouselInner.value;
        if (container) {
          container.scrollBy({
            left: -container.clientWidth / 3,
            behavior: 'smooth'
          });
        }
      };
  
      const scrollRight = async () => {
        await nextTick(); // Ensure DOM is updated
        const container = carouselInner.value;
        if (container) {
          container.scrollBy({
            left: container.clientWidth / 3,
            behavior: 'smooth'
          });
          if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
            container.scrollLeft = 0; // Reset to the start
          }
        }
      };
  
      const changeTab = (tab) => {
        activeTab.value = tab;
      };

      const goToReservations = () => {
        router.push({ path: '/reservations', query: { city: activeTab.value } });
      };
  
      const startAutoScroll = () => {
        scrollInterval = setInterval(() => {
          scrollRight();
        }, 2000);
      };
  
      const stopAutoScroll = () => {
        clearInterval(scrollInterval);
      };
  
      onMounted(() => {
        startAutoScroll();
      });
  
      onBeforeUnmount(() => {
        clearInterval(scrollInterval);
      });
  
      return {
        filteredItems,
        scrollLeft,
        scrollRight,
        changeTab,
        carouselInner,
        activeTab,
        startAutoScroll,
        stopAutoScroll,
        goToReservations
      };
    }
  };
  </script>
  
  <style scoped>
  .carousel-inner {
    overflow-x: hidden;
  }
  </style>
  