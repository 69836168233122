<template>
    <footer class="footer has-background-dark has-text-white">
      <div class="container">
        <div class="content has-text-centered">
          <p>
          
            <router-link to="/prices" class="navbar-item">Cijene</router-link>
            <router-link to="/about" class="navbar-item">Gdje poslujemo</router-link>
            <router-link to="/terms" class="navbar-item">Uvjeti korištenja</router-link>
            <router-link to="/about" class="navbar-item">O nama</router-link>
          </p>
          <p class="anton-sc-regular">&copy; 2024 Kombi.To.Go</p>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {


    name: 'AppFooter',
    data() {
      return {}
        
    },
    methods: {
        
    },
};
  </script>
  
  <style scoped>
  /* Add any additional scoped styles here */
  </style>
  