<template>
    <section class="section">
      <div class="container">
        <h1 class="title">Cijene</h1>
        <p>Informacije o cijenama naših usluga.</p>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'PricesView'
  };
  </script>
  
  <style scoped>
  /* Add any specific styles for the Prices page here */
  </style>
  