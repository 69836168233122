<template>
  <section class="section">
    <div class="container">
      <h1 class="title">O nama</h1>
      <p>Informacije o našoj kompaniji i timu.</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutView'
};
</script>

<style scoped>
/* Add any specific styles for the About page here */
</style>
